import { Box, MenuItem, styled } from '@mui/material';

export const StyledMenuItemList = styled(MenuItem)({
  width: '100%',
  '&:hover': { backgroundColor: 'transparent' },
});

export const StyledBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    float: 'none',
    textAlign: 'center',
    width: '100%',
  },
  [theme.breakpoints.up('md')]: {
    float: 'right',
  },
}));
