import { useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  createTheme,
  Modal,
  ThemeProvider,
  Typography,
  IconButton,
} from '@mui/material';
import ImageCropper from '../../../../components/imageCrop/index';
import { ACTION_TYPES } from '../reducer';
import MultiSelect from './MultiSelect';
import TextFieldWrapper from './TextFieldWrapper';

const StyledModalBody = styled(Box)(({ theme }) => ({
  width: '90%',
  maxWidth: '600px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '30px 20px',
  background: 'rgb(255, 255, 255)',
  borderRadius: '.15em',
  overflowY: 'auto',
  maxHeight: '80vh',
  [theme.breakpoints.up('sm')]: {
    width: '80%',
    padding: '30px 30px',
  },
  [theme.breakpoints.up('md')]: {
    width: '600px',
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '10px',
  flexDirection: 'column',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
}));

const theme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: '0.875rem',
        },
      },
    },
  },
});

const skillsType = [
  'Programming Languages',
  'Web Development',
  'Mobile App Development',
  'Game Development',
  'Desktop Development',
  'Embedded Systems',
  'Project Management',
  'Design',
  'Environment',
  'Tools',
  'Frameworks',
  'Libraries',
  'Databases',
  'Cloud Computing',
  'DevOps',
  'Version Control',
  'Testing',
  'Security',
  'Networking',
  'Operating Systems',
  'Data Structures and Algorithms',
  'Machine Learning and AI',
  'IoT',
  'Big Data',
  'Automation',
  'Collaboration Tools',
  'IDEs',
  'Methodologies',
  'Architecture',
  'Other',
  'Marketing',
  'Sales',
  'Finance',
  'Human Resources',
];

export default function ModalWindow({ isOpened, setIsOpened, state, dispatch }) {
  const handleInputChange = useCallback(
    ({ name, value }) => {
      dispatch({
        type: ACTION_TYPES.SET_FORM_VALUES,
        payload: {
          formValues: { ...state.formValues, [name]: value },
        },
      });
    },
    [state.formValues],
  );

  const handleInputBlur = useCallback(
    ({ name, value }) => {
      let error = false;

      if (!value.length) error = true;

      dispatch({
        type: ACTION_TYPES.SET_FORM_ERRORS,
        payload: { ...state.formErrors, [name]: { status: error, messages: null } },
      });
    },
    [state.formErrors],
  );

  const validateForm = () => {
    const newErrors = {
      title: { status: !state.formValues.title.length, messages: null },
      url: { status: !state.formValues.url.length, messages: null },
      tags: { status: !state.formValues.tags.length, messages: null },
      description: { status: !state.formValues.description.length, messages: null },
    };

    dispatch({ type: ACTION_TYPES.SET_FORM_ERRORS, payload: newErrors });

    return !Object.values(newErrors)
      .map(({ status }) => status)
      .some(Boolean);
  };

  const handleCloseModal = () => {
    setIsOpened(false);
    dispatch({ type: ACTION_TYPES.RESET_ALL });
    dispatch({ type: ACTION_TYPES.SET_IS_IMAGE_UPLOAD, payload: false });
  };

  const disabledBtnStyle = () => {
    if (
      state.formErrors.title.status ||
      state.formErrors.description.status ||
      state.formErrors.tags.status ||
      !state.isImageUpload
    ) {
      return {
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
        color: 'rgba(0, 0, 0, 0.26)',
      };
    }

    return {
      backgroundColor: '#7553EA',
    };
  };

  const disabledBtn = useMemo(() => {
    return (
      state.formErrors.title.status ||
      state.formErrors.description.status ||
      state.formErrors.tags.status ||
      !state.isImageUpload
    );
  }, [
    state.formErrors.title.status,
    state.formErrors.description.status,
    state.formErrors.tags.status,
    state.isImageUpload,
    state.image,
  ]);

  useEffect(() => {
    if (state.isOpenedUpdate) {
      dispatch({
        type: ACTION_TYPES.SET_FORM_VALUES,
        payload: {
          formValues: {
            ...state.formValues,
          },
          previewImage: state.formValues.imageUrl,
        },
      });
      dispatch({ type: ACTION_TYPES.SET_IS_IMAGE_UPLOAD, payload: true });
    }
  }, [state.isOpenedUpdate]);

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={isOpened}
        onClose={handleCloseModal}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '5em',
          overflowY: 'auto',
        }}
      >
        <StyledModalBody>
          <StyledBox>
            <Typography fontSize="medium" fontWeight={500} textTransform="uppercase">
              Add skill
            </Typography>
          </StyledBox>
          <StyledBox>
            <TextFieldWrapper
              placeholder="Title"
              inputName="title"
              value={state.formValues.title}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              error={state.formErrors.title.status}
              helperText={state.formErrors.title.messages}
            />
          </StyledBox>
          <StyledBox>
            <TextFieldWrapper
              placeholder="Version"
              inputName="version"
              value={state.formValues.version}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              type="number"
            />
          </StyledBox>
          <StyledBox>
            <TextFieldWrapper
              placeholder="Url"
              inputName="url"
              value={state.formValues.url}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              error={state.formErrors.url.status}
              helperText={state.formErrors.url.messages}
            />
          </StyledBox>
          <StyledBox>
            <MultiSelect
              formValues={state.formValues}
              handleInputChange={handleInputChange}
              handleInputBlur={handleInputBlur}
              formErrors={state.formErrors}
              skillsType={skillsType}
            />
          </StyledBox>
          <StyledBox>
            <TextFieldWrapper
              placeholder="Description"
              inputName="description"
              value={state.formValues.description}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              error={state.formErrors.description.status}
              helperText={state.formErrors.description.messages}
              multiline
              rows={4}
            />
          </StyledBox>
          <StyledBox justifyContent="center" style={{ position: 'relative' }}>
            {state.previewImage ? (
              <div>
                <img src={state.previewImage} alt="test" width="auto" height="300px" />
                <IconButton
                  onClick={() => {
                    dispatch({ type: ACTION_TYPES.RESET_PREVIEW_IMAGE });
                    dispatch({ type: ACTION_TYPES.SET_IS_IMAGE_UPLOAD, payload: false });
                  }}
                  style={{ position: 'absolute', top: 0, right: 0, zIndex: '1000' }}
                >
                  <CloseIcon style={{ fontSize: '1.2em' }} />
                </IconButton>
              </div>
            ) : (
              <ImageCropper dispatch={dispatch} state={state} />
            )}
          </StyledBox>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            {state.isOpenedUpdate ? (
              <Button
                variant="contained"
                style={disabledBtnStyle()}
                onClick={() => {
                  if (!state.formValues.version) {
                    // eslint-disable-next-line no-param-reassign
                    state.formValues.version = 0;
                  }

                  if (state.previewImage) {
                    dispatch({ type: ACTION_TYPES.BATCH_SET, payload: { isUpdate: true } });
                    return;
                  }
                  dispatch({
                    type: ACTION_TYPES.BATCH_SET,
                    payload: { isUpdate: true, isCropImage: true },
                  });
                }}
                disabled={disabledBtn}
              >
                Update skill
              </Button>
            ) : (
              <Button
                variant="contained"
                style={disabledBtnStyle()}
                onClick={() => {
                  if (!state.formValues.version) {
                    // eslint-disable-next-line no-param-reassign
                    state.formValues.version = 0;
                  }

                  if (validateForm()) {
                    dispatch({ type: ACTION_TYPES.SET_IS_CROP_IMAGE, payload: true });
                    dispatch({ type: ACTION_TYPES.BATCH_SET, payload: { isCreate: true } });
                  }
                }}
                disabled={disabledBtn}
              >
                Save skill
              </Button>
            )}
          </Box>
        </StyledModalBody>
      </Modal>
    </ThemeProvider>
  );
}

ModalWindow.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  setIsOpened: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  state: PropTypes.shape({
    isCropImage: PropTypes.bool.isRequired,
    isImageUpload: PropTypes.bool.isRequired,
    isOpenedUpdate: PropTypes.bool.isRequired,
    image: PropTypes.string,
    formValues: PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      version: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      tags: PropTypes.arrayOf(PropTypes.string).isRequired,
      imageUrl: PropTypes.string.isRequired,
    }).isRequired,
    formErrors: PropTypes.shape({
      title: PropTypes.shape({
        status: PropTypes.bool.isRequired,
        messages: PropTypes.string,
      }),
      url: PropTypes.shape({
        status: PropTypes.bool.isRequired,
        messages: PropTypes.string,
      }),
      tags: PropTypes.shape({
        status: PropTypes.bool.isRequired,
        messages: PropTypes.string,
      }),
      description: PropTypes.shape({
        status: PropTypes.bool.isRequired,
        messages: PropTypes.string,
      }),
      version: PropTypes.shape({
        status: PropTypes.bool.isRequired,
        messages: PropTypes.string,
      }),
    }).isRequired,
    previewImage: PropTypes.string,
    isCreate: PropTypes.bool.isRequired,
    isUpdate: PropTypes.bool.isRequired,
  }).isRequired,
};

ModalWindow.defaultPropTypes = {
  state: {
    previewImage: null,
    image: null,
  },
};
