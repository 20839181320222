import { Box, styled, Typography } from '@mui/material';

export const StyledFieldsContainer = styled(Box)(() => ({
  textAlign: 'initial',
  fontSize: '18px',
  borderRadius: '4px',
  padding: '1em',
  border: '1px solid rgb(211, 211, 211)',
  margin: '5px',
}));

export const StyledTypography = styled(Typography)(() => ({
  fontWeight: 600,
}));
