import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import Timeline from '@mui/lab/Timeline';
import { Typography, Tooltip, Grid, Box, IconButton } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import EnglishLevel from '../../assets/images/EnglishLevel.svg';
import UKFlag from '../../assets/images/UKFlag.svg';
import Loading from '../../components/Loading';
import RegenerateAvatarModal from '../../components/RegenerateAvatarModal/RegenerateAvatarModal';
import UserContext from '../../hooks/context/UserContext';
import useAvatar from '../../hooks/useAvatar';
import { BigHead } from '../../lib/core.cjs.development';
import ApiService from '../../services/api.service';
import TokenService from '../../services/token.service';
import BasicPage from '../../templates/BasicPage';
import EnglishLevelsEnum from '../Admin/Users/enums/englishLevels.enum';
import HexGrid from '../Hexagon/components/HexGrid';
import ActualLevel from './components/ActualLevel';
import FinishedLevel from './components/FinishedLevel';
import FutureLevel from './components/FutureLevel';

export const scrollbarStyle = makeStyles({
  scrollBar: {
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
      borderRadius: '10px',
      backgroundColor: '#F5F5F5',
    },
    '&::-webkit-scrollbar': {
      width: '12px',
      backgroundColor: '#F5F5F5',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '10px',
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,.3)',
      backgroundColor: '#7553EA',
    },
  },
});

const StyledTooltip = styled(Tooltip)(() => ({
  background: '#000000a1',
  color: '#ffffff',
  border: ' 3px solid white',
  width: '30px',
  height: '30px',
  '&:hover': {
    background: '#000000a1',
  },
}));

export default function Progress({ title }) {
  const classes = scrollbarStyle();

  const { user, setUser } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [levels, setLevels] = useState([]);
  const [userLevel, setUserLevel] = useState(null);
  const [skills, setSkills] = useState([]);
  const [englishLevel, setEnglishLevel] = useState();
  const [, setAnchorEl] = useState(null);
  const [, setItemForAction] = useState(null);
  const [honeycombColumns, setHoneycombColumns] = useState(null);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [departmentName, setDepartmentName] = useState({ name: 'nodejs' });
  const [isSaveDisabled, setSaveDisabled] = useState(true);

  let isBeforeCurrentLevel = true;

  const userAvatar = useMemo(() => user?.avatar, [user]);
  const gender = user?.gender || 'male';
  const { values, generateAvatar } = useAvatar({ initialValues: userAvatar, gender });

  useEffect(() => {
    async function fetchData() {
      let actualUser = null;

      try {
        actualUser = await ApiService.developer.getMySelf();
        const [hydraEnglish] = await Promise.all([
          ApiService.developer.getHydraEnglishLevel(user.email),
        ]);

        const hydraEnglishLevel = EnglishLevelsEnum[hydraEnglish.speaking];

        if (hydraEnglishLevel.value !== actualUser.englishLevel)
          await ApiService.developer.updateUser(user._id, {
            englishLevel: hydraEnglishLevel.value,
          });
      } catch {
        if (!actualUser.englishLevel) {
          await ApiService.developer.updateUser(user._id, {
            englishLevel: EnglishLevelsEnum[0].value,
          });
        }
      }

      const [departmentLevels, userSkills, departments] = await Promise.all([
        ApiService.developer.getDepartmentLevels(user.departmentId),
        ApiService.developer.getUserSkills(),
        ApiService.developer.getDepartments(),
      ]);

      actualUser = await ApiService.developer.getMySelf();

      const columns = Math.ceil(userSkills.length / 8);

      if (columns < 5) {
        setHoneycombColumns(columns);
      }

      const department = departments.find((el) => el._id === user.departmentId);

      setDepartmentName(department.name);
      setEnglishLevel(actualUser.englishLevel);
      setUserLevel(actualUser.level);
      setLevels(departmentLevels);
      setSkills(userSkills);
      setIsLoading(false);
    }

    fetchData();
  }, [user.departmentId, user._id]);

  const onOpenModal = () => {
    setIsModalOpened(true);
  };

  const handleOnSaveModal = async () => {
    try {
      const updatedUser = { ...user, avatar: values };
      await ApiService.developer.updateUser(user._id, updatedUser);
      const guestToken = await ApiService.developer.refreshToken(updatedUser._id);

      TokenService.removeAccessToken();
      TokenService.removeGuestToken();

      TokenService.setGuestToken(guestToken);

      setUser(updatedUser);
      setSaveDisabled(true);
      setIsModalOpened(false);
    } catch (error) {
      console.error('Error updating avatar:', error);
    }
  };

  const handleOnCloseModal = () => {
    setIsModalOpened(false);
  };

  const englishLevelDisplay = englishLevel
    ? Object.values(EnglishLevelsEnum).find((level) => level.value === englishLevel)?.display
    : '';

  useEffect(() => {
    if (userAvatar !== values) {
      setSaveDisabled(false);
    }
  }, [user, userAvatar, values]);

  return (
    <BasicPage title={title}>
      {isLoading ? (
        <Loading />
      ) : (
        <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
          <Grid
            sx={{
              maxWidth: '300px',
              width: '100%',
              mt: '24px',
              ml: '60px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            <BigHead {...userAvatar} />
            {englishLevel && (
              <Box sx={{ position: 'relative', userSelect: 'none' }}>
                <Box
                  component="img"
                  src={EnglishLevel}
                  sx={{
                    width: '100px',
                    ml: '-50px',
                    mt: '-120px',
                    transform: 'scale(-1, 1)',
                  }}
                />
                <Box
                  sx={{
                    position: 'relative',
                    mt: '-45px',
                    transform: 'translate(-50%, -50%)',
                    color: 'black',
                    fontWeight: 'bold',
                    textAlign: 'center',
                  }}
                >
                  <Tooltip title={englishLevelDisplay} arrow>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Box
                        component="img"
                        src={UKFlag}
                        alt="UK Flag"
                        sx={{ width: '19px', height: '15px', mr: 0.5, mb: 0.2 }}
                      />
                      <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>
                        {englishLevel}
                      </Typography>
                    </Box>
                  </Tooltip>
                </Box>
              </Box>
            )}
            <Box position="absolute" bottom={0} sx={{ right: englishLevel ? 110 : 60 }}>
              <StyledTooltip title="Edit avatar" sx={{}}>
                <IconButton onClick={onOpenModal}>
                  <EditIcon sx={{ width: '0.7em' }} />
                </IconButton>
              </StyledTooltip>
            </Box>
          </Grid>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={12} md={4} xl={2} display="flex" justifyContent="center">
              <Timeline
                position="alternate"
                sx={{
                  mt: '30px',
                  minWidth: { xs: '300px', md: '400px' },
                  maxWidth: '400px',
                }}
              >
                {levels &&
                  userLevel &&
                  levels.map((level) => {
                    if (level._id === userLevel) {
                      isBeforeCurrentLevel = false;
                      return (
                        <ActualLevel key={level.key} levelKey={level.key} title={level.title} />
                      );
                    }

                    if (isBeforeCurrentLevel) {
                      return (
                        <FinishedLevel key={level.key} levelKey={level.key} title={level.title} />
                      );
                    }

                    return <FutureLevel key={level.key} levelKey={level.key} title={level.title} />;
                  })}
              </Timeline>
            </Grid>
            {!!honeycombColumns && (
              <Grid item xs={12} md={4} xl={2} display="flex" justifyContent="center">
                <Box
                  width="100%"
                  minWidth="120px"
                  maxWidth={honeycombColumns ? `${120 + honeycombColumns * 80}px` : '100%'}
                  style={{ overflowX: 'clip' }}
                  className={classes.scrollBar}
                  display={honeycombColumns === 0 ? 'none' : 'block'}
                >
                  <HexGrid
                    isUserPage
                    setAnchorEl={setAnchorEl}
                    setItemForAction={setItemForAction}
                    skills={skills}
                    columns={honeycombColumns}
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      )}
      {isModalOpened ? (
        <RegenerateAvatarModal
          isOpen={isModalOpened}
          onClose={handleOnCloseModal}
          onSave={handleOnSaveModal}
          isSaveDisabled={isSaveDisabled}
          userAvatar={values}
          currentAvatar={userAvatar}
          generateAvatar={generateAvatar}
          department={departmentName}
          gender={gender}
        />
      ) : (
        ''
      )}
    </BasicPage>
  );
}

Progress.propTypes = {
  title: PropTypes.string.isRequired,
};
