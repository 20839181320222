import { useEffect, useState } from 'react';

const useReviewsStatus = (
  onApprove,
  onDecline,
  showRecommendationTooltip,
  showCommentTooltip,
  setShowRecommendationTooltip,
  setShowCommentTooltip,
  parent,
  details,
) => {
  const [hidden, setHidden] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const [approveClicked, setApproveClicked] = useState(false);
  const [declineClicked, setDeclineClicked] = useState(false);

  const [declineAnchorMenu, setDeclineAnchorMenu] = useState(null);
  const [declineReason, setDeclineReason] = useState(null);
  const [updateDecline, setUpdateDecline] = useState(false);

  const [recommendationAnchorMenu, setRecommendationAnchorMenu] = useState(null);
  const [recommendationReason, setRecommendationReason] = useState(null);
  const [updateRecommendation, setUpdateRecommendation] = useState(false);

  const openDeclineMenu = Boolean(declineAnchorMenu);
  const openRecommendationMenu = Boolean(recommendationAnchorMenu);

  useEffect(() => {
    if (showCommentTooltip) {
      setDeclineReason(details.comment);
      setShowTooltip(true);
    }
    if (showRecommendationTooltip) {
      setRecommendationReason(details.recommendation);
      setShowTooltip(true);
    }
  }, [showCommentTooltip, showRecommendationTooltip, showTooltip]);

  const handleOnTooltip = () => {
    setShowTooltip(false);
  };

  const handleOnClickDecline = (event) => {
    handleOnTooltip();
    setShowCommentTooltip(false);
    setShowRecommendationTooltip(false);

    event.stopPropagation();
    setDeclineAnchorMenu(event.currentTarget);
  };

  const handleOnClickRecommendation = (event) => {
    handleOnTooltip();
    setShowCommentTooltip(false);

    event.stopPropagation();
    setRecommendationAnchorMenu(event.currentTarget);
  };

  const handleOnCloseDecline = () => {
    setDeclineAnchorMenu(null);
    setUpdateDecline(false);
  };

  const handleOnCloseRecommendation = () => {
    setRecommendationAnchorMenu(null);
    setUpdateRecommendation(false);
  };

  const handleOnRecommendationButtonClick = () => {
    setUpdateRecommendation(false);
    setApproveClicked(true);
    setDeclineClicked(false);

    setDeclineReason(null);
    setDeclineAnchorMenu(null);

    if (recommendationReason) {
      onApprove(parent._id, details.itemId, recommendationReason);
      setShowTooltip(true);
      handleOnCloseRecommendation();
    }

    setHidden(true);
  };

  const handleOnDeclineButtonClick = () => {
    setUpdateDecline(false);
    setDeclineClicked(true);
    setApproveClicked(false);

    setRecommendationReason(null);
    setRecommendationAnchorMenu(null);

    if (declineReason) {
      onDecline({
        goalId: parent._id,
        itemId: details.itemId,
        comment: declineReason,
      });

      setShowTooltip(true);
      setHidden(true);
    }
  };

  const handleOnApproveButtonClick = () => {
    setApproveClicked(true);
    setDeclineClicked(false);

    setDeclineReason(null);
    setDeclineAnchorMenu(null);

    onApprove(parent._id, details.itemId);

    setHidden(true);
  };

  const handleOnUpdateRecommendation = (event) => {
    setUpdateRecommendation(true);

    event.stopPropagation();

    setRecommendationAnchorMenu(event.currentTarget);
  };

  const handleOnUpdateDecline = (event) => {
    setUpdateDecline(true);

    event.stopPropagation();

    setDeclineAnchorMenu(event.currentTarget);
  };

  useEffect(() => {
    if (showCommentTooltip) {
      setDeclineReason(details.comment);
      setShowTooltip(true);
    }
    if (showRecommendationTooltip) {
      setRecommendationReason(details.recommendation);
      setShowTooltip(true);
    }
  }, [showCommentTooltip, showRecommendationTooltip, showTooltip]);

  return {
    approveClicked,
    declineClicked,
    hidden,
    handleOnApproveButtonClick,
    showTooltip,
    recommendationReason,
    handleOnClickRecommendation,
    openRecommendationMenu,
    recommendationAnchorMenu,
    handleOnCloseRecommendation,
    setRecommendationReason,
    handleOnRecommendationButtonClick,
    updateRecommendation,
    handleOnUpdateRecommendation,
    updateDecline,
    declineReason,
    handleOnClickDecline,
    declineAnchorMenu,
    openDeclineMenu,
    handleOnCloseDecline,
    setDeclineReason,
    handleOnDeclineButtonClick,
    handleOnUpdateDecline,
  };
};

export default useReviewsStatus;
