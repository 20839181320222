import { useEffect, useState } from 'react';
import goalsConstants from '../../../constants/goals.constants';
import apiService from '../../../services/api.service';

const useUserNestedSkillDetails = (parent, details, onMenuItemClick) => {
  const [nestedAnchorMenu, setNestedAnchorMenu] = useState(null);
  const [showRecommendationTooltip, setShowRecommendationTooltip] = useState(false);
  const [showCommentTooltip, setShowCommentTooltip] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState([]);
  const [hoveredLink, setHoveredLink] = useState(null);
  const [iframePosition, setIframePosition] = useState({ top: 0, left: 0 });
  const [previewData, setPreviewData] = useState(null);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isSaveDisabled, setSaveDisabled] = useState(true);

  useEffect(() => {
    if (details.recommendation) {
      setShowRecommendationTooltip(true);
      console.log('details.recommendation :>> ', details.recommendation);
    }
    if (details.status === goalsConstants.GOAL_STATUSES.DECLINED && details.comment) {
      setShowCommentTooltip(true);
    }
  }, [details]);

  const openNestedMenu = Boolean(nestedAnchorMenu);

  const handleOnClickNested = (event) => {
    event.stopPropagation();
    setNestedAnchorMenu(event.currentTarget);
  };

  const handleOnCloseNested = () => {
    setNestedAnchorMenu(false);
  };

  const handleNestedMenuItemClick = (status) => (event) => {
    setNestedAnchorMenu(null);

    return onMenuItemClick(event, {
      fullData: details,
      parentId: parent.itemId,
      itemId: details.itemId,
      status,
    });
  };

  const outdatedModal = () => {
    setNestedAnchorMenu(null);
    setIsModalOpened(true);
  };

  const handleOnCloseModal = () => {
    setIsModalOpened(false);
  };

  const handleOnSaveModal = async (data) => {
    await apiService.developer.sendReport(data);

    setSaveDisabled(true);
    setIsModalOpened(false);
  };

  const handleLinkMouseEnter = async (link, event) => {
    const linkRect = event.target.getBoundingClientRect();
    setHoveredLink(link);
    setIframePosition({
      top: linkRect.top + window.scrollY,
      left: linkRect.right + 10 + window.scrollX,
    });

    try {
      const response = await apiService.developer.getPreview(link);
      setPreviewData(response);
    } catch (error) {
      console.error('Error fetching link preview:', error);
    }
  };

  const handleLinkMouseLeave = () => {
    setHoveredLink(null);
    setPreviewData(null);
  };

  useEffect(() => {
    const handleScroll = () => {
      setHoveredLink(null);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return {
    setIsOverflowing,
    openNestedMenu,
    handleOnClickNested,
    nestedAnchorMenu,
    handleOnCloseNested,
    handleNestedMenuItemClick,
    outdatedModal,
    isOverflowing,
    handleLinkMouseEnter,
    handleLinkMouseLeave,
    showRecommendationTooltip,
    showCommentTooltip,
    setShowRecommendationTooltip,
    setShowCommentTooltip,
    hoveredLink,
    previewData,
    iframePosition,
    isModalOpened,
    isSaveDisabled,
    setSaveDisabled,
    handleOnCloseModal,
    handleOnSaveModal,
  };
};

export default useUserNestedSkillDetails;
