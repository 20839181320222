import React from 'react';
import PropTypes from 'prop-types';
import { InfoOutlined } from '@mui/icons-material';
import DescriptionIcon from '@mui/icons-material/Description';
import { Button, Box, Grid, Menu, TextField, Tooltip, IconButton } from '@mui/material';
import GoalsConstants from '../../../constants/goals.constants';
import useReviewsStatus from '../hooks/useReviewsStatus';
import { StyledBox, StyledMenuItemList } from '../styles/reviewStatus.styled';

export default function ReviewStatus({
  admin,
  parent,
  details,
  onApprove,
  onDecline,
  showRecommendationTooltip,
  showCommentTooltip,
  setShowRecommendationTooltip,
  setShowCommentTooltip,
}) {
  const {
    approveClicked,
    declineClicked,
    hidden,
    handleOnApproveButtonClick,
    showTooltip,
    recommendationReason,
    handleOnClickRecommendation,
    openRecommendationMenu,
    recommendationAnchorMenu,
    handleOnCloseRecommendation,
    setRecommendationReason,
    handleOnRecommendationButtonClick,
    updateRecommendation,
    handleOnUpdateRecommendation,
    updateDecline,
    declineReason,
    handleOnClickDecline,
    declineAnchorMenu,
    openDeclineMenu,
    handleOnCloseDecline,
    setDeclineReason,
    handleOnDeclineButtonClick,
    handleOnUpdateDecline,
  } = useReviewsStatus(
    onApprove,
    onDecline,
    showRecommendationTooltip,
    showCommentTooltip,
    setShowRecommendationTooltip,
    setShowCommentTooltip,
    parent,
    details,
  );

  return (
    <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
      {((!approveClicked && declineClicked) ||
        (!hidden &&
          admin &&
          (details.status === GoalsConstants.GOAL_STATUSES.DONE ||
            details.status === GoalsConstants.GOAL_STATUSES.DECLINED))) && (
        <Grid item xs={12} md="auto">
          <StyledBox>
            <Button
              sx={{ m: 1 }}
              onClick={handleOnApproveButtonClick}
              size="small"
              variant="contained"
              color="success"
            >
              Approve
            </Button>
          </StyledBox>
        </Grid>
      )}

      {((approveClicked && !declineClicked) ||
        (!declineClicked &&
          !hidden &&
          admin &&
          details.status === GoalsConstants.GOAL_STATUSES.APPROVED)) && (
        <Grid item xs={12} md="auto">
          <StyledBox>
            {!(showTooltip && recommendationReason) && (
              <Button
                sx={{ m: 1 }}
                onClick={handleOnClickRecommendation}
                size="small"
                variant="contained"
                color="primary"
                id="recomandation-basic-menu"
                aria-controls={openRecommendationMenu ? 'recomandation-basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openRecommendationMenu ? 'true' : undefined}
              >
                Recommendation
              </Button>
            )}

            <Menu
              id="recomandation-basic-menu"
              anchorEl={recommendationAnchorMenu}
              open={openRecommendationMenu}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              onClose={handleOnCloseRecommendation}
              MenuListProps={{ 'aria-labelledby': 'recomandation-basic-menu' }}
            >
              <StyledMenuItemList disableRipple>
                <TextField
                  multiline
                  id="standard-recommendation-reason"
                  placeholder="Enter your recommendation"
                  fullWidth
                  inputProps={{ maxLength: 512 }}
                  sx={{ pr: 2 }}
                  value={recommendationReason}
                  onChange={(event) => setRecommendationReason(event.target.value)}
                />

                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={handleOnRecommendationButtonClick}
                  disabled={!recommendationReason}
                >
                  Save
                </Button>
              </StyledMenuItemList>
            </Menu>

            {(updateRecommendation || (showTooltip && recommendationReason)) && (
              <Tooltip
                title={recommendationReason}
                sx={{ m: 0.7 }}
                onChange={(event) => setRecommendationReason(event.target.value)}
                onClick={handleOnUpdateRecommendation}
              >
                <IconButton aria-label="description" size="small">
                  <InfoOutlined />
                </IconButton>
              </Tooltip>
            )}
          </StyledBox>
        </Grid>
      )}

      {(updateDecline ||
        (approveClicked && !declineClicked) ||
        (!hidden &&
          admin &&
          (details.status === GoalsConstants.GOAL_STATUSES.DONE ||
            details.status === GoalsConstants.GOAL_STATUSES.APPROVED))) && (
        <Grid item xs={12} md="auto">
          <StyledBox>
            {!(showTooltip && declineReason) && (
              <Button
                sx={{ m: 1 }}
                onClick={handleOnClickDecline}
                size="small"
                variant="contained"
                color="error"
                id="decline-basic-menu"
                aria-controls={openDeclineMenu ? 'decline-basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openDeclineMenu ? 'true' : undefined}
              >
                Decline
              </Button>
            )}

            <Menu
              id="decline-basic-menu"
              anchorEl={declineAnchorMenu}
              open={openDeclineMenu}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              onClose={handleOnCloseDecline}
              MenuListProps={{ 'aria-labelledby': 'decline-basic-menu' }}
            >
              <StyledMenuItemList disableRipple>
                <TextField
                  multiline
                  id="standard-decline-reason-comment"
                  placeholder="Enter the reason"
                  fullWidth
                  inputProps={{ maxLength: 512 }}
                  sx={{ pr: 2 }}
                  onChange={(event) => setDeclineReason(event.target.value)}
                  value={declineReason}
                />

                <Button
                  size="large"
                  variant="contained"
                  color="error"
                  onClick={handleOnDeclineButtonClick}
                  disabled={!declineReason}
                >
                  Decline
                </Button>
              </StyledMenuItemList>
            </Menu>
          </StyledBox>
        </Grid>
      )}

      {(updateDecline || (showTooltip && declineReason)) && (
        <Tooltip
          title={declineReason}
          sx={{ m: 0.7 }}
          onChange={(event) => setDeclineReason(event.target.value)}
          onClick={handleOnUpdateDecline}
        >
          <IconButton aria-label="description" size="small">
            <DescriptionIcon />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
}

ReviewStatus.propTypes = {
  admin: PropTypes.bool,
  details: PropTypes.shape({
    itemId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    successCriteria: PropTypes.arrayOf(PropTypes.string).isRequired,
    status: PropTypes.string.isRequired,
    comment: PropTypes.string,
    recommendation: PropTypes.string,
    materials: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  parent: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    itemId: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.string).isRequired,
    status: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    skillType: PropTypes.string.isRequired,
  }).isRequired,

  showRecommendationTooltip: PropTypes.bool.isRequired,
  showCommentTooltip: PropTypes.bool.isRequired,
  setShowRecommendationTooltip: PropTypes.func.isRequired,
  setShowCommentTooltip: PropTypes.func.isRequired,

  onApprove: PropTypes.func,
  onDecline: PropTypes.func,
};

ReviewStatus.defaultProps = {
  admin: false,
  onApprove: () => null,
  onDecline: () => null,
};
