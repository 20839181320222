import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import HourglassTopRoundedIcon from '@mui/icons-material/HourglassTopRounded';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  Alert,
  Box,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import GoalsConstants from '../../../constants/goals.constants';
import useUserNestedSkillDetails from '../hooks/useUserNestedSkillDetails';
import OutdatedModal from '../modals/OutdatedModal';
import { StyledFieldsContainer, StyledTypography } from '../styles/userNestedSkillDetails.styled';
import AdminAttachmentButton from './AdminAttachmentButton';
import AttachmentButton from './AttachmentButton';
import LinkPreview from './LinkPreview';
import MaterialTypeIcon from './MaterialTypeIcon';
import ReviewStatus from './ReviewStatus';

export default function UserNestedSkillDetails({
  admin,
  parent,
  details,
  onMenuItemClick,
  onApprove,
  onDecline,
  onLinkClick,
  isDisabled,
  isInteractive,
  isHomePage,
  isReviewedPage,
  onConfirmCriteriaClick,
  uploadedItems,
  isReviewsPage,
}) {
  const emRefs = useRef([]);
  const linkRefs = useRef({});

  const {
    setIsOverflowing,
    openNestedMenu,
    handleOnClickNested,
    nestedAnchorMenu,
    handleOnCloseNested,
    handleNestedMenuItemClick,
    outdatedModal,
    isOverflowing,
    handleLinkMouseEnter,
    handleLinkMouseLeave,
    showRecommendationTooltip,
    showCommentTooltip,
    setShowRecommendationTooltip,
    setShowCommentTooltip,
    hoveredLink,
    previewData,
    iframePosition,
    isModalOpened,
    isSaveDisabled,
    setSaveDisabled,
    handleOnCloseModal,
    handleOnSaveModal,
  } = useUserNestedSkillDetails(parent, details, onMenuItemClick);

  useEffect(() => {
    const overflowingIndices = [];

    for (let i = 0; i < emRefs.current.length; i += 1) {
      if (
        emRefs.current[i].offsetWidth +
          emRefs.current[i].parentElement.innerHTML.split('&nbsp;')[0].length * 8 >
        emRefs.current[i].parentElement.offsetWidth
      ) {
        overflowingIndices.push(i);
      }
    }

    setIsOverflowing(overflowingIndices);
  }, []);

  return (
    <StyledFieldsContainer key={details.itemId}>
      <Box>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid>
            <Grid container alignItems="center">
              <Grid item>
                <StyledTypography sx={{ fontSize: '18px' }}>{details.name}</StyledTypography>
              </Grid>

              {!isReviewedPage && (
                <Grid>
                  {details.status === GoalsConstants.GOAL_STATUSES.APPROVED && (
                    <CheckCircleRoundedIcon
                      color="success"
                      fontSize="small"
                      sx={{ verticalAlign: 'middle' }}
                    />
                  )}

                  {details.status === GoalsConstants.GOAL_STATUSES.DECLINED && (
                    <CancelRoundedIcon
                      color="error"
                      fontSize="small"
                      sx={{ verticalAlign: 'middle' }}
                    />
                  )}

                  {!admin && details.status === GoalsConstants.GOAL_STATUSES.DONE && (
                    <CheckCircleRoundedIcon
                      color="warning"
                      fontSize="small"
                      sx={{ verticalAlign: 'middle' }}
                    />
                  )}

                  {details.status === GoalsConstants.GOAL_STATUSES.IN_PROGRESS && (
                    <HourglassTopRoundedIcon fontSize="small" sx={{ verticalAlign: 'middle' }} />
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>

          {isInteractive &&
            !isDisabled &&
            !admin &&
            details.status !== GoalsConstants.GOAL_STATUSES.APPROVED && (
              <Grid>
                <IconButton
                  id="nested-basic-button"
                  disabled={admin}
                  aria-controls={openNestedMenu ? 'nested-basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openNestedMenu ? 'true' : undefined}
                  onClick={handleOnClickNested}
                >
                  <MoreHorizIcon fontSize="medium" />
                </IconButton>
                <Menu
                  id="nested-basic-menu"
                  anchorEl={nestedAnchorMenu}
                  open={openNestedMenu}
                  onClose={handleOnCloseNested}
                  MenuListProps={{ 'aria-labelledby': 'nested-basic-button' }}
                >
                  {details.status === GoalsConstants.GOAL_STATUSES.DONE && (
                    <MenuItem
                      onClick={handleNestedMenuItemClick(GoalsConstants.GOAL_STATUSES.IN_PROGRESS)}
                    >
                      In Progress
                    </MenuItem>
                  )}

                  {details.status === GoalsConstants.GOAL_STATUSES.IN_PROGRESS && (
                    <MenuItem
                      onClick={handleNestedMenuItemClick(GoalsConstants.GOAL_STATUSES.DONE)}
                      disabled={
                        !details.criteria.every((detail) =>
                          !detail.contentType
                            ? true
                            : detail.contentInput || uploadedItems[detail.itemId],
                        )
                      }
                    >
                      Done
                    </MenuItem>
                  )}
                  <MenuItem onClick={outdatedModal}>Issue</MenuItem>
                </Menu>
              </Grid>
            )}
        </Grid>
      </Box>

      <Box sx={{ padding: '16px 0 0' }}>
        <StyledTypography sx={{ fontWeight: 600 }}>Success Criteria:</StyledTypography>
        <List sx={{ padding: '8px 0 0 8px' }}>
          {details.criteria.map(({ successCriteria, contentType, itemId, contentInput }, index) => (
            <ListItem key={successCriteria} sx={{ padding: '0' }}>
              <ListItemText>
                - {successCriteria}
                &nbsp;
                {admin || isReviewedPage ? (
                  <AdminAttachmentButton
                    itemId={itemId}
                    onConfirmCriteriaClick={onConfirmCriteriaClick}
                    emRefs={emRefs}
                    index={index}
                    contentType={contentType}
                    contentInput={contentInput}
                    isOverflowing={isOverflowing}
                  />
                ) : (
                  <AttachmentButton
                    itemId={itemId}
                    uploadedItems={uploadedItems}
                    onConfirmCriteriaClick={onConfirmCriteriaClick}
                    contentType={contentType}
                    contentInput={contentInput}
                  />
                )}
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Box>

      <Box sx={{ padding: '16px 0 0' }}>
        <Grid container alignItems="end" justifyContent="space-between">
          <Grid>
            <StyledTypography sx={{ fontWeight: 600 }}>Materials:</StyledTypography>
            <List sx={{ padding: '8px 0 0 8px' }}>
              {details.materials.map((material) => (
                <ListItem key={`${material.name}-${material.link}`} sx={{ padding: '0' }}>
                  <ListItemIcon sx={{ minWidth: 0 }}>
                    <MaterialTypeIcon type={material.type} />
                  </ListItemIcon>
                  <Link
                    ref={(el) => {
                      linkRefs.current[material.link] = el;
                    }}
                    onClick={() => {
                      onLinkClick({ link: material.link, taskId: details.itemId });
                    }}
                    href={material.link}
                    target="_blank"
                    rel="noopener"
                    onMouseEnter={(event) => handleLinkMouseEnter(material.link, event)}
                    onMouseLeave={handleLinkMouseLeave}
                  >
                    <ListItemText primary={material.name} />
                  </Link>
                </ListItem>
              ))}
            </List>
          </Grid>
          <ReviewStatus
            admin={admin}
            parent={parent}
            details={details}
            onApprove={onApprove}
            onDecline={onDecline}
            showRecommendationTooltip={showRecommendationTooltip}
            showCommentTooltip={showCommentTooltip}
            setShowRecommendationTooltip={setShowRecommendationTooltip}
            setShowCommentTooltip={setShowCommentTooltip}
          />
        </Grid>
      </Box>

      {isHomePage &&
      details.status === GoalsConstants.GOAL_STATUSES.IN_PROGRESS &&
      details.comment.length ? (
        <Box sx={{ padding: '16px 0 0' }}>
          <Alert severity="warning" sx={{ fontSize: '14px' }}>
            {details.comment}
          </Alert>
        </Box>
      ) : (
        ''
      )}

      {isReviewedPage && details.comment && (
        <Box sx={{ padding: '16px 0 0' }}>
          <Alert severity="warning" sx={{ fontSize: '14px' }}>
            {details.comment}
          </Alert>
        </Box>
      )}

      {!isReviewsPage && !details.comment && details.recommendation && (
        <Box sx={{ padding: '16px 0 0' }}>
          <Alert severity="info" sx={{ fontSize: '14px' }}>
            {details.recommendation}
          </Alert>
        </Box>
      )}

      {hoveredLink && previewData && (
        <LinkPreview previewData={previewData} position={iframePosition} />
      )}

      {isModalOpened && (
        <OutdatedModal
          isSaveDisabled={isSaveDisabled}
          setSaveDisabled={setSaveDisabled}
          isOpen={isModalOpened}
          onClose={handleOnCloseModal}
          onSave={handleOnSaveModal}
          parent={parent}
          details={details}
        />
      )}
    </StyledFieldsContainer>
  );
}

UserNestedSkillDetails.propTypes = {
  admin: PropTypes.bool,
  details: PropTypes.shape({
    itemId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    successCriteria: PropTypes.arrayOf(PropTypes.string).isRequired,
    criteria: PropTypes.arrayOf(PropTypes.object),
    status: PropTypes.string.isRequired,
    comment: PropTypes.string,
    recommendation: PropTypes.string,
    materials: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  parent: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    itemId: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.string).isRequired,
    status: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    skillType: PropTypes.string.isRequired,
  }).isRequired,
  isDisabled: PropTypes.bool,
  onMenuItemClick: PropTypes.func,
  onConfirmCriteriaClick: PropTypes.func.isRequired,
  onApprove: PropTypes.func,
  onDecline: PropTypes.func,
  onLinkClick: PropTypes.func,
  isInteractive: PropTypes.bool.isRequired,
  isHomePage: PropTypes.bool.isRequired,
  isReviewedPage: PropTypes.bool.isRequired,
  uploadedItems: PropTypes.shape({}).isRequired,
  isReviewsPage: PropTypes.bool.isRequired,
};

UserNestedSkillDetails.defaultProps = {
  admin: false,
  isDisabled: false,
  onMenuItemClick: () => null,
  onApprove: () => null,
  onDecline: () => null,
  onLinkClick: () => null,
};
